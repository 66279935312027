











import mixins from 'vue-typed-mixins'
import ColMixin from './ColMixin.vue'

export default mixins(ColMixin).extend({
  name: 'MultiSelectCol',
  computed: {
    options () {
      var opts = [...this.column.Options]

      if (!Array.isArray(this.data[this.column.Key])) {
        return opts
      }

      this.data[this.column.Key].forEach((value: any) => {
        if (opts.filter(opt => opt.Value === value).length === 0) {
          opts.push({Value: value, Name: value})
        }
      })

      return opts
    }
  },
  methods: {
    toggle (option: any) {
      if (this.blur) return;
      let newValue = this.data[this.column.Key] ? [...this.data[this.column.Key]] : []
      let current = newValue.indexOf(option.Value)
      if (current !== -1) {
        newValue.splice(current, 1)
      } else {
        newValue.push(option.Value)
      }
      this.update(this.column.Key, newValue)
    }
  }
})
