






import Vue from 'vue'

export default Vue.extend({
  name: 'DatasetList',
  async created() {
    this.$store.getters["data/list"]('Dataset')
    .then((list: any) => {
      list.Data.forEach((el: any) => {
        if (el.Collection === 'Dataset') {
          this.$router.push({ name: 'DatasetView', params: { dataset: el._id } })
        }
      });
    })
  },
});
