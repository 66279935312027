






















import Vue from 'vue'
import _ from 'lodash-es'

export default Vue.extend({
  name: "DatasetView",
  data() {
    return {
      loading: true as boolean,
      error: null as any,
      value: "" as string,
    };
  },
  computed: {
    providerCtx () {
      return {
        limit: typeof this.$route.query.limit === 'string' ? parseInt(this.$route.query.limit) : 50,
        filters: typeof this.$route.query.filters === 'string' ? JSON.parse(this.$route.query.filters) : {},
        sort: this.$route.query.sort || null,
        order: this.$route.query.order == "desc" ? "desc" : "asc"
      }
    },
    columns() {
      return typeof this.$route.query.c === 'string' ? this.$route.query.c.split(",") : [];
    },
    dataset() {
      return this.$store.getters["data/getDatasets"].find((d: any) => d._id == this.datasetID);
    },
    datasetID() {
      return this.$route.params.dataset ? this.$route.params.dataset : null;
    },
    schema() {
      let schema = this.$store.getters["data/getSchema"]
      if (!schema || !this.dataset || schema._id !== this.dataset.Schema) {
        return null
      }
      return schema;
    },
  },
  watch: {
    '$route.name' () {
      this.$store.commit('ui/hideColumns');
    },
    datasetID(value, oldvalue) {
      if (!value || oldvalue == value || !this.dataset) return;
      this.$store.dispatch("data/updateSchema", {
        datasetID: value,
      })
      .then(() => {
        this.error = null;
      })
      .catch((err: any) => {
        this.error = err
      });
    },
  },
  async created() {
    try {
      await this.$store.dispatch("data/updateDatasets")
      if (this.datasetID == null) {
        return;
      }
      await this.$store.dispatch("data/updateSchema", {
        datasetID: this.datasetID,
      })
      this.error = null;
    } catch (err: any) {
      this.error = err
    }
  },
  methods: {
    updateCtx(newCtx: any) {
      let params = _.cloneDeep(newCtx)
      if (params.filters) {
        params.filters = JSON.stringify(params.filters)
      } else {
        params.filters = ""
      }
      const query = Object.assign({}, this.$route.query, params);
      let stringQuery = {} as {[key: string]: string};
      for (const [k, v] of Object.entries(query) as [string, any]) {
        if (v === null) {
          continue
        }
        stringQuery[k] = v.toString();
      }
      if (!_.isEqual(stringQuery, this.$route.query)) {
        this.$router.replace({ query });
      }
    },
    updateColumns(col: string[]) {
      let newCol = col.join(",");
      if (newCol != this.$route.query.c) {
        this.$router.replace({
          query: Object.assign({}, this.$route.query, { c: newCol }),
        });
      }
    }
  }
});
