import axios, { AxiosRequestConfig } from 'axios'
import { Module } from 'vuex'
import { setupCache } from 'axios-cache-adapter'

const encodeGetParams = (p: {[key: string]: string}) => {
  return Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
}

const cache = setupCache({
  readHeaders: true,
  exclude: { query: false },
  maxAge: 15 * 60 * 1000
})

const State = {
  session: null as any,
  apiClient: axios.create({
    adapter: cache.adapter,
    baseURL: '/api/data/',
    responseType: 'json',
    timeout: 60 * 1000, // 60 seconds
    withCredentials: true
  }),
  datasets: [] as any[],
  schema: {} as any
}

export default {
  namespaced: true,
  state: () => State,
  getters: {
    isLoggedIn(state): boolean {
      return !!state.session
    },
    list(state) {
      return (entity: string, params={}) => state.apiClient.get(`${entity}`, { params })
        .then(resp => resp.data)
    },
    export () {
      return (entity: string, params={}) => new Promise(resolve => {
        window.open(`/api/data/${entity}/export?` + encodeGetParams(params))
        resolve(true)
      })
    },
    getDatasets(state) {
      return state.datasets;
    },
    getSchema(state) {
      return state.schema;
    }
  },
  actions: {
    async single({ state }, { entity, id, params={} }) {
      const resp = await state.apiClient.get(`${entity}/${id}`, { params });
      return resp.data;
    },
    async patchSingle ({ state }, { entity, id, patch }) {
      const resp = await state.apiClient.patch(`${entity}/${id}`, { $set: patch });
      return resp.data;
    },
    async get({ state }, { entity, path }) {
      const resp = await state.apiClient.get(`${entity}/${path}`);
      return resp.data;
    },
    async delete({ state }, { entity, id }) {
      const resp = await state.apiClient.delete(`${entity}/${id}`);
      return resp.data;
    },
    async post({ state }, { entity, formData }) {
      const opts = {} as AxiosRequestConfig;
      if (entity === 'import/upload') {
        opts.timeout = 16 * 60 * 1000; // 16 minutes
      }
      const resp = await state.apiClient.post(`${entity}`, formData, opts);
      return resp.data;
    },
    login() {
      return Promise.reject(new Error("not implemented"))
    },
    updateDatasets({ commit, getters }) {
      return getters.list("Dataset").then(
        (response: any) => {
          commit('setDatasets', response.Data);
          return response.Data;
        },
        (error: any) => {
          console.log(error);
        }
      );
    },
    async updateSchema({ commit, state, dispatch }, { datasetID }) {
      commit('setSchema', null);
      const dataset = state.datasets.find(d => d._id == datasetID);
      if (!dataset) {
        throw new Error('Could not find schema: ' + datasetID);
      }
      const response = await dispatch('single', { entity: "Schema", id: dataset.Schema });
      commit('setSchema', response);
      return response.Data;
    }
  },
  mutations: {
    login() {
      throw new Error("not implemented")
    },
    setDatasets(state, datasets) {
      state.datasets = datasets;
    },
    setSchema(state, schema) {
      state.schema = schema;
    }
  }
} as Module<typeof State, {}>;
