















































































































import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    page: {
      type: Number as PropType<number>,
      default: 1
    },
    limit: {
      type: Number as PropType<number>,
      default: 25
    },
    total: {
      type: Number as PropType<number>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  data() {
    return {
        selectedLimit: this.limit,
        isLoading: this.loading
    }
  },
  computed: {
    lastPage() {
      return Math.ceil(this.total / this.limit);
    }
  },
  watch: {
    loading(loading) {
      this.isLoading = loading;
    }
  },
  methods: {
    goTo(page: number) {
      this.$emit('goTo', page);
    },
    setLimit() {
      this.$emit('setLimit', this.selectedLimit);
    }
  }
})
