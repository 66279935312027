import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs';

import DatasetList from '@/views/DatasetList.vue'
import DatasetView from '@/views/DatasetView.vue'
import SchemaView from '@/views/SchemaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dataset/'
  },
  {
    path: '/dataset/',
    name: 'DatasetList',
    component: DatasetList
  },
  {
    path: '/dataset/:dataset',
    name: 'DatasetView',
    component: DatasetView
  },
  {
    path: '/schema/:id',
    name: 'SchemaView',
    component: SchemaView,
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('@/views/Media.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {encode: false});
    return result ? ('?' + result) : '';
  }
})

export default router
