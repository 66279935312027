




































import mixins from 'vue-typed-mixins'
import Mustache from 'mustache'
import ColMixin from "./ColMixin.vue";

export default mixins(ColMixin).extend({
  name: "LinkCol",
  mixins: [ColMixin],
  data() {
    return {
      editing: false,
      style: {} as any,
      styleOuter: {} as any
    };
  },
  methods: {
    startEdit () {
      this.editing = !this.editing

      if (this.editing) {
        this.$nextTick(() => {
          (this.$refs.input as any).focus()
        })
      }
    }
  },
  computed: {
    linkAndTitle () : string[] {
      let templates = this.column.Value.split('|')
      if (templates[0] === '') {
        templates[0] = `{{${this.column.Key}}}`
      }
      if (templates.length == 1) {
        templates.push(templates[0])
      }

      return [
        Mustache.render(templates[0], this.data),
        Mustache.render(templates[1], this.data)
      ]
    }
  }
});
