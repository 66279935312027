/**
 * 🌾 SvenskaFoder Produktdatabas Frontend ( SFPDF )
 *    Made by Duva <kontakt@duva.se>
 */
import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import { Integrations as SentryTracingIntegration } from '@sentry/tracing'
import VueInputAutowidth from 'vue-input-autowidth'
import AsyncComputed from 'vue-async-computed'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueConfirmDialog from 'vue-confirm-dialog';
import vco from "v-click-outside";
import infiniteScroll from "vue-infinite-scroll";
import VueLazyload from "vue-lazyload";
import VueObserveVisibility from 'vue-observe-visibility'
import VueCurrencyInput from 'vue-currency-input'

import DynamicTable from '@/components/DynamicTable.vue'
import columnsResizeable from '@/directives/columns-resizeable'
import SvfSvg from '@/SvfSvg.vue'


require('@/assets/main.scss');

Vue.use(infiniteScroll)
Vue.use(VueConfirmDialog)
Vue.use(vco)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.directive('columns-resizeable', columnsResizeable)
Vue.component('svf-svg', SvfSvg)
Vue.use(VueCurrencyInput, { globalOptions: { currency: 'SEK', locale: 'sv-SE' } })
Vue.use(VueLazyload)

Vue.config.productionTip = process.env.NODE_ENV === 'development'

if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV !== 'development') {
  Sentry.init({
    Vue: Vue,
    environment: process.env.VUE_APP_ENV,
    dsn: 'https://d8b2cf9d49aa4be489e4d69343809c21@sentry.duva.se/16',
    integrations: [
      new SentryTracingIntegration.BrowserTracing()
    ],
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    }
  })
}

library.add(fas, far, fal, fad)

Vue.component('font-awesome', FontAwesomeIcon)
Vue.use(VueInputAutowidth)
Vue.use(AsyncComputed,
  process.env.VUE_APP_ENV !== 'development' ? {
    useRawError: true,
    errorHandler (err: string | Error) {
      Sentry.captureException(err)
    }
  } as any : {
    useRawError: true
  } as any
)
Vue.use(VueObserveVisibility)
Vue.use(Vuex)

Vue.component('DynamicTable', DynamicTable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
