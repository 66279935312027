







































































import { PropType } from "vue";
import mixins from 'vue-typed-mixins'
import ColumnTypesMixin from "@/components/ColumnTypesMixin";

interface Schema {
  Columns: any[]
}
type AsyncComputedFields = {
  schema: Schema
}
export default mixins(ColumnTypesMixin).extend({
  name: "ArrayTable",
  props: {
    schemaId: {
        type: String as PropType<string>,
        required: true
    },
    dataset: {
        type: Object as PropType<any>,
        required: true
    },
    rowId: {
        type: String as PropType<string>,
        required: true
    },
    colKey: {
        type: String as PropType<string>,
        required: true
    }
  },
  data() {
    const rtn = {
      entity: 'Products',
      editArray: this.dataset[this.colKey] as any[] || []
    }
    return rtn as typeof rtn & AsyncComputedFields;
  },
  mounted() {

  },
  asyncComputed: {
    schema () : Promise<any> {
        return this.$store.dispatch('data/single', { entity: 'Schema', id: this.schemaId })
    },
  },
  computed: {
    rows(): number {
      return this.editArray.length
    }
  },
  methods: {
    save() {
      this.$emit('save', this.editArray)
    },
    removeRow(index: number) {
      (this as any).$confirm({
        message: "Vill du verkligen radera raden?",
        button: {
          no: "Nej",
          yes: "Ja"
        },
        callback: (confirm: boolean) => {
          if (!confirm) return;
          this.editArray.splice(index, 1)
        }
      });
    },
    addRow() {
      let newObj = {} as any
      for (let col of this.schema.Columns) {
        newObj[col.Key] = null
      }
      this.editArray.push(newObj)
    },
    createRemoveButton(rowIndex: number) {
      let button = document.createElement('button')
      button.addEventListener('click', () => this.removeRow(rowIndex))
      button.setAttribute('style', 'background: white; cursor: pointer; border: none;')
      button.innerHTML = '-'
      return button
    },
    updateField(e: any, row: any) {
      this.editArray[row][e.key] = e.value
    }
  }
});
