






















import mixins from 'vue-typed-mixins'
import ColMixin from './ColMixin.vue'

export default mixins(ColMixin).extend({
  name: "ArrayCol",
  components: {

  },
  data() {
    return {
      showArrayTable: false,
    };
  },
  methods: {
    hideArrayTable() : void {
      this.showArrayTable = false
    },
    save(value: any[]) {
      this.update(this.column.Key, value)
    }
  },
  beforeCreate: function () {
    this.$options.components!.ArrayTable = require('../ArrayTable.vue').default
  }
})
