







import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: "SvfSvg",
  props: {
    src: {
      type: String as PropType<string>,
      required: true
    }
  }
})
