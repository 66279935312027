



























import mixins from 'vue-typed-mixins'
import ColMixin from "./ColMixin.vue";

export default mixins(ColMixin).extend({
  name: "MoneyCol",
  data() {
    return {
      editing: false,
      dirtyHackToggleOnOff: true
    }
  },
  methods: {
    dirtyHack () {
      this.dirtyHackToggleOnOff = false
      this.$nextTick(() => {
        this.dirtyHackToggleOnOff = true
      })
    },
    maybeUpdate (key: string, value: number | null) {
      let strValue = value === null ? '0' : Number(value).toString()

      if (strValue === this.data[this.column.Key]) {
        return // Prevent <Money> triggering redundant update events
      }

      this.update(key, strValue)
    }
  }
});
