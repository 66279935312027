











import Vue, { PropType } from 'vue'


const validImageTypes = new RegExp(/(jpg|jpeg|png|webp|tiff|gif)$/)

export default Vue.extend({
  name: 'MediaPreview',
  props: {
    path: {
      type: String as PropType<string>,
      default: () : string => ''
    },
    size: {
      type: Number as PropType<number>,
      default: () : number => 30
    }
  },
  computed: {
    validImageType () : boolean {
      return validImageTypes.test(this.path)
    }
  },
  methods: {
    toUrl (size: number, path: string) : string {
      if (this.validImageType) {
        return `/api/media/preview/${size}x${size},sc/${path}`
      }
      return require('@/assets/images/preview-file-link.png');
    }
  }
})
