


































import mixins from "vue-typed-mixins";
import ColMixin from "./ColMixin.vue";
import vClickOutside from "v-click-outside";
import TableInTable from "../TableInTable.vue";
import EntityReference from "../EntityReference.vue";

export default mixins(ColMixin).extend({
  name: "OneToManyCol",
  components: {
    TableInTable,
    EntityReference
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      showTable: false
    };
  },
  watch: {
    blur(bool) {
      if (bool) this.showTable = false;
    }
  },
  methods: {
    internalClick() {
      if (!this.blur) this.showTable = true;
    },
    externalClick() {
      this.showTable = false;
    }
  }
});
