







































































































































































































































import Vue from "vue";
import _ from "lodash-es";
import draggable from "vuedraggable";
import ValueLengthCol from "@/components/columns/ValueLengthCol.vue";
import ManyToOneCol from "@/components/columns/ManyToOneCol.vue";

// Hack to get types to work for async computed.
// @see https://github.com/foxbenjaminfox/vue-async-computed/issues/100
type AsyncComputedFields = {
  datasetDataset?: any;
  schema?: any;
}

interface ColumnOptionShowPermissive {
  id: string;
  disabled: boolean;
  value: boolean;
}

interface ColumnOption {
  id?: string;
  name: string;
  value: string;
  disableColumns: string[];
  showPermissive: ColumnOptionShowPermissive[];
  default?: boolean;
}
/**
 * This is not a Column as it should be defined in the Schema that's saved!
 * This is a special column that's only to be used on this view.
 * (See how these are keyed lowercase instead of uppercase)
 * You're welcome to transform it to comply with regular Column if you want
 * but I think it will be some work both here and additions to the regular Column type.
 * // Philip
 */
interface Column {
  key: string;
  title: string;
  type: string;
  options?: string[] | ColumnOption[];
}
const isColumnOptionArray = (object: any): object is ColumnOption[] => {
  return Array.isArray(object) && (object.length === 0 || typeof object[0] !== 'string');
}

interface SchemaViewData {
  name: string;
  keyColumn: string;
  columns: Column[];
  rows: any[];
}

export default Vue.extend({
  components: {
    ValueLengthCol,
    ManyToOneCol,
    draggable
  },
  data(): SchemaViewData & AsyncComputedFields {
    return {
      name: "",
      keyColumn: "",
      columns: [
        {
          key: "Name",
          title: "Namn",
          type: "text"
        },
        {
          key: "Key",
          title: "Nyckel",
          type: "text"
        },
        {
          key: "Parent",
          title: "Fältbeskrivning",
          type: "text"
        },
        {
          key: "Type",
          title: "Typ",
          type: "select",
          options: [
            {
              name: "Textfält, en rad",
              value: "string",
              disableColumns: ["Value", "MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: [
                {
                  id: "regex09",
                  disabled: true,
                  value: true
                },
                {
                  id: "regex#",
                  disabled: true,
                  value: true
                }
              ],
              default: true
            },
            {
              name: "Textfält, flera rader",
              value: "text",
              disableColumns: ["Value", "ArraySchema"],
              showPermissive: [
                {
                  id: "regex09",
                  disabled: true,
                  value: true
                },
                {
                  id: "regex#",
                  disabled: true,
                  value: true
                }
              ]
            },
            {
              name: "Länk",
              value: "link",
              disableColumns: ["MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: [
                {
                  id: "regex09",
                  disabled: true,
                  value: true
                },
                {
                  id: "regex#",
                  disabled: true,
                  value: true
                }
              ],
              default: true
            },
            {
              name: "Datum",
              value: "date",
              disableColumns: ["Value", "MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: [
                {
                  id: "regex09",
                  disabled: true,
                  value: true
                },
                {
                  id: "regex#",
                  disabled: true,
                  value: true
                }
              ],
              default: true
            },
            {
              name: "Enkeltval",
              value: "select",
              disableColumns: ["Unique", "MinRowValue", "MaxRowValue", "Regex", "ArraySchema"],
              showPermissive: []
            },
            {
              name: "Flerval",
              value: "multiselect",
              disableColumns: ["Unique", "MinRowValue", "MaxRowValue", "Regex", "ArraySchema"],
              showPermissive: []
            },
            {
              name: "Nummer",
              value: "number",
              disableColumns: ["Value", "MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: [
                {
                  id: "regex#",
                  disabled: false,
                  value: false
                },
                {
                  id: "regexao",
                  disabled: false,
                  value: false
                }
              ]
            },
            {
              name: "Pengar",
              value: "money",
              disableColumns: ["Value", "MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: []
            },
            {
              name: "Radio",
              value: "boolean",
              disableColumns: ["MinRowValue", "MaxRowValue", "ArraySchema"],
              showPermissive: []
            },
            {
              name: "Relation (Välj flera)",
              value: "one-to-many",
              disableColumns: [
                "Value",
                "MinValue",
                "MaxValue",
                "MinRowValue",
                "MaxRowValue",
                "Unique",
                "Required",
                "Regex", 
                "ArraySchema"
              ],
              showPermissive: []
            },
            {
              name: "Relation (Välj en)",
              value: "many-to-one",
              disableColumns: [
                "Value",
                "MinValue",
                "MaxValue",
                "MinRowValue",
                "MaxRowValue",
                "Unique",
                "Required",
                "Regex", 
                "ArraySchema"
              ],
              showPermissive: []
            },
            {
              name: "Media",
              value: "media",
              disableColumns: [
                "Value",
                "MinValue",
                "MaxValue",
                "MinRowValue",
                "MaxRowValue",
                "Unique",
                "Required",
                "Regex", 
                "ArraySchema"
              ],
              showPermissive: []
            },
            {
              name: "Array",
              value: "array",
              disableColumns: [
                "Value",
                "MinValue",
                "MaxValue",
                "MinRowValue",
                "MaxRowValue",
                "Unique",
                "Required",
                "Regex"
              ],
              showPermissive: []
            },
          ]
        },
        {
          key: "Value",
          title: "Värde",
          type: "text"
        },
        {
          key: "RelatedTo",
          title: "Relaterad till",
          type: "dataset"
        },
        {
          key: "ArraySchema",
          title: "Arrayschema",
          type: "schema"
        },
        {
          key: "Unique",
          title: "Unikt fält",
          type: "checkbox",
          options: ["Ja", "Nej"]
        },
        {
          key: "Required",
          title: "Obligatorisk",
          type: "checkbox",
          options: ["Ja", "Nej"]
        },
        {
          key: "MinValue",
          title: "Min.",
          type: "number"
        },
        {
          key: "MinRowValue",
          title: "",
          type: "numberrow"
        },
        {
          key: "MaxValue",
          title: "Max.",
          type: "number"
        },
        {
          key: "MaxRowValue",
          title: "",
          type: "numberrow"
        },
        {
          key: "Regex",
          title: "Reguljära uttryck",
          type: "text"
        },
        {
          key: "Permissive",
          title: "Tillåtande",
          type: "permissive",
          options: [
            {
              id: "regex09",
              name: "0-9"
            },
            {
              id: "regex#",
              name: "Specialtecken"
            },
            {
              id: "regexao",
              name: "A-Ö"
            }
          ]
        },
        {
          key: "Default",
          title: "Standardkolumn",
          type: "checkbox"
        }
      ],
      rows: []
    } as SchemaViewData & AsyncComputedFields;
  },
  asyncComputed: {
    async datasetDataset() : Promise<any> {
      return this.$store.getters["data/list"]("Dataset").then((list: any) => {
        return list.Data.find((el: any) => el.Collection === "Dataset");
      });
    },
    async schema() : Promise<any> {
      return this.$store.getters["data/list"]("Schema").then((list: any) => {
        return list.Data;
      });
    }
  },
  computed: {
    typeColumn(): Column | null {
      return this.columns.find(c => c.key == "Type") || null;
    },
    id(): string {
      return this.$route.params.id;
    },
    isNew(): boolean {
      return this.id == "create";
    }
  },
  async created() {
    if (!this.isNew) this.getSchema();
  },
  methods: {
    addRow() : void {
      const columnOption = ((this.typeColumn || {}).options || [] as any[]).find(
        (o: ColumnOption) => o.default == true
      );
      let row = {} as any;
      this.columns.forEach((column: Column, index: number) => {
        if (column.key == "Permissive") {
          row[column.key] = columnOption.showPermissive.map((p: ColumnOptionShowPermissive) => {
            return {
              id: p.id,
              value: p.value,
              disabled: p.disabled
            };
          });
        } else {
          row[column.key] = {
            value:
              column.key == "Type"
                ? (column.options as ColumnOption[])[0].value
                : null,
            disabled: columnOption.disableColumns.includes(
              this.columns[index].key
            )
          };
        }
      });
      this.rows.push(row);
    },
    selectRowType(rowIndex: number, colIndex: string, value: any) : void {
      const option = ((this.typeColumn || {}).options || [] as any[]).find(
        (o: ColumnOption) => o.value == value
      );
      for (let column of this.columns as Column[]) {
        if (column.key == "ArraySchema" && value == "array") {
          this.rows[rowIndex][column.key].disabled = option.disableColumns.includes(column.key);
          this.rows[rowIndex][column.key].value = this.schema[0]._id; 
        }
        if (column.key == "Permissive") {
          this.rows[rowIndex][column.key] = option.showPermissive.map((p: ColumnOptionShowPermissive) => {
            return {
              id: p.id,
              value: p.value,
              disabled: p.disabled
            };
          });
        } else {
          this.rows[rowIndex][
            column.key
          ].disabled = option.disableColumns.includes(column.key);
          this.rows[rowIndex][
            column.key
          ].value = !option.disableColumns.includes(column.key)
            ? this.rows[rowIndex][column.key].value
            : null;
        }
      }
      this.rows[rowIndex][colIndex].value = value;
    },
    updateRowPermissive(rowIndex: number, colIndex: string, optionIndex: number, value: any) : void {
      this.rows[rowIndex][colIndex][optionIndex].value = value;
    },
    updateRow(rowIndex: number, colIndex: string, value: any) : void {
      this.rows[rowIndex][colIndex].value = value;
    },
    deleteRow(index: number) : void {
      this.rows.splice(index, 1);
    },
    save() : void {
      let schema = this.create();
      if (!this.isNew) schema._id = this.id;
      let takenKeys = new Map<string, number>();
      for (let row of schema.Columns) {
        if (takenKeys.has(row.Key)) {
          // Conflict! Never save if this occurs. Needs manual intervention
          alert('Ett problem uppstod. Schemat har inte sparats.\n\nNyckeln "'+ row.Key +'" är inte unik!\nLös det och spara igen.')
          return;
        }
        takenKeys.set(row.Key, 1)
      }
      this.$store
        .dispatch("data/post", {
          entity: "save/schema",
          formData: schema
        })
        .then(() => {
          this.rows = [];
          this.name = "";
          this.keyColumn = "";
          this.$router.push({ path: "/" });
        });
    },
    create() {
      let schema = {
        _id: null as string | null,
        Name: this.name,
        KeyColumn: this.keyColumn,
        DefaultColumns: [] as any[],
        Columns: [] as any[]
      };
      for (let editRow of this.rows) {
        let row = _.cloneDeep(editRow);
        let type = row["Type"].value;
        for (let column of this.columns as Column[]) {
          const value = row[column.key].value;
          if (column.key == "Name") {
            row["Name"] = value;
          } else if (column.key == "Type") {
            row["Type"] = value;
          } else if (column.key == "Value" && type == "multiselect") {
            if (value != null) {
              var originalOptionValueMap = new Map();
              for (let opt of row["Options"] || []) {
                originalOptionValueMap.set(opt.Name, opt.Value);
              }
              row["Options"] = value.split(",").map((item: string) => {
                let name = item.trim();
                let value = originalOptionValueMap.get(name);
                if (!value) {
                  value = name;
                }
                return {
                  Name: name,
                  Value: value
                };
              });
            }
            row[column.key] = value;
          } else if (column.key == "Permissive") {
            row[column.key] = row[column.key]
              .filter((p: ColumnOptionShowPermissive) => !p.disabled)
              .map((p: ColumnOptionShowPermissive) => {
                return {
                  id: p.id,
                  value: p.value
                };
              });
          } else if (column.type == "checkbox") {
            row[column.key] = value === true || value == "true";
          } else if(column.type == "schema" && type == "array") {
            row[column.key] = value;
          } else if (
            (column.type == "number" || column.type == "numberrow") &&
            value != null
          ) {
            row[column.key] = parseInt(value);
          } else {
            row[column.key] = value;
          }
        }
        // safe guard, if no roles are set
        if (!row.Roles) {
          row.Roles = ["admin"];
        }

        // Don't change key if it has already been set
        if (!row["Key"] || row["Key"] === "") {
          row["Key"] = this.slug(row["Name"]);
        }
        schema["Columns"].push(row);
        if (row["Default"]) {
          schema["DefaultColumns"].push(row["Key"])
        }
      }
      return schema
    },
    getPermissive(row: any) : ColumnOption[] {
      const showPermissive = ((this.typeColumn || {}).options || [] as any[]).find(
        (col: ColumnOption) => col.value == row.Type.value
      ).showPermissive as ColumnOptionShowPermissive[];
      const column = this.columns.find(c => c.key == "Permissive")
      if (!column || !isColumnOptionArray(column.options)) {
        return []
      }
      return column.options
        .filter(c =>
          showPermissive
            .map(p => p.id)
            .includes(c.id || '')
        );
    },
    slug(str: string) : string {
      if (!str) return str;
      return str
        .replace(/[^a-zA-Z]/gi, "")
        .replace(/\b\w/g, s => s.toUpperCase())
        .replace(/\s/g, "")
        .trim();
    },
    getSchema() : void {
      this.$store.dispatch('data/single', { entity: 'Schema', id: this.id }).then((result: any) => {
        (result.Columns || []).forEach((row: any) => {
          this.columns.forEach((column: Column) => {
            const option = ((this.typeColumn || {}).options || [] as any[]).find(
              (o: ColumnOption) => o.value == row.Type.value
            );

            if (column.type == "permissive") {
              row[column.key] = option.showPermissive.map(
                (p: ColumnOptionShowPermissive) => {
                  return {
                    id: p.id,
                    value: p.value,
                    disabled: p.disabled
                  };
                }
              );
            } else if (
              column.key == "Value" &&
              (row.Type.value == "multiselect" || row.Type == "multiselect")
            ) {
              row[column.key] = {
                value: (row.Options || []).map((opt: any) => opt.Name).join(","),
                disabled: false
              };
            } else {
              row[column.key] = {
                value: row[column.key] ? row[column.key] : null,
                disabled: (option
                  ? option.disableColumns.includes(column.key)
                  : false) || column.key === 'Key'
              };
            }
          });
          this.rows.push(row);
        });
        this.name = result.Name;
        this.keyColumn = result.KeyColumn || '';
      });
    }
  }
});
