var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:{ subtable: _vm.isSubtable }},
    _vm.resizingColumn !== null
      ? {
        mousemove: function ($event) { return _vm.columnResize($event); },
        mouseup: function () { return _vm.columnResizeStop(); }
      }
      : {}
  ),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideColumns),expression:"!hideColumns"}],staticClass:"available-columns"},[_c('draggable',{attrs:{"group":"columns","tag":"ul"},model:{value:(_vm.availableColumns),callback:function ($$v) {_vm.availableColumns=$$v},expression:"availableColumns"}},_vm._l((_vm.availableColumns),function(element){return _c('li',{key:element.Key,staticClass:"item"},[_c('span',{staticClass:"tag"},[_c('font-awesome',{attrs:{"icon":['far', 'database']}}),_vm._v(_vm._s(_vm.schema.Name)+" ")],1),_c('span',{staticClass:"parent"},[_vm._v(_vm._s(element.Parent))]),_c('span',{staticClass:"col-name"},[_vm._v(_vm._s(element.Name))])])}),0)],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],ref:"tablecontainer",staticStyle:{"overflow-x":"scroll","overflow-y":"hidden"},attrs:{"infinite-scroll-disabled":_vm.infiniteScrollDisabled,"infinite-scroll-distance":_vm.providerCtx.limit},on:{"scroll":function($event){return _vm.handleTableScroll($event)}}},[_c('table',{class:{ 'dragging-columns': _vm.isDraggingColumns, checkable: _vm.checkable }},[_c('thead',[_c('tr',{staticClass:"column-parents"},_vm._l((_vm.editColumns),function(column,i){return _c('th',{key:'parent-' + column.Key,class:[
              'parent',
              i !== 0 && _vm.editColumns[i - 1].Parent == column.Parent
                ? 'already-seen'
                : null,
              { 'column-fixed': _vm.lockedColumns.includes(column.Key) }
            ],style:({
              width: column.Width + 'px',
              left: _vm.fixedFromLeft(column.Key, i)
            })},[_c('span',{style:({ width: column.Width + 'px', overflow: 'hidden' })},[_vm._v(_vm._s(column.Parent))])])}),0),_c('draggable',{staticClass:"column-titles",attrs:{"group":"columns","tag":"tr"},on:{"start":function($event){_vm.isDraggingColumns = true},"end":function($event){_vm.isDraggingColumns = false}},model:{value:(_vm.editColumns),callback:function ($$v) {_vm.editColumns=$$v},expression:"editColumns"}},_vm._l((_vm.editColumns),function(column,i){return _c('th',{key:column.Key,class:{
              'column-sort': _vm.providerCtx.sort == column.Key,
              'column-fixed': _vm.lockedColumns.includes(column.Key)
            },style:({
              width: column.Width + 'px',
              left: _vm.fixedFromLeft(column.Key, i)
            }),on:{"click":function($event){return _vm.setSort(column.Key)}}},[_c('span',{style:({ width: column.Width + 'px', overflow: 'hidden' })},[_vm._v(" "+_vm._s(column.Name)+" "),(_vm.providerCtx.sort == column.Key)?_c('span',[_c('font-awesome',{attrs:{"icon":_vm.providerCtx.order == 'desc'
                      ? ['fas', 'sort-up']
                      : ['fas', 'sort-down']}})],1):_vm._e()]),_c('div',{class:[
                'column-lock',
                _vm.lockedColumns.includes(column.Key) ? 'column-locked' : null
              ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.columnToggleLock(column)}}},[_c('font-awesome',{attrs:{"icon":[
                  'far',
                  _vm.lockedColumns.includes(column.Key) ? 'lock' : 'lock-open'
                ],"fixed-width":""}})],1),_c('div',{class:[
                'column-resize',
                _vm.resizingColumn === column.Key ? 'column-resizing' : null
              ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {})($event)},"dragenter":function($event){$event.stopPropagation();$event.preventDefault();return (function () {})($event)},"dragover":function($event){$event.stopPropagation();$event.preventDefault();return (function () {})($event)},"drag":function($event){$event.stopPropagation();$event.preventDefault();return (function () {})($event)},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.columnResizeStart(column)}}})])}),0),_c('tr',{staticClass:"column-filters"},_vm._l((_vm.editColumns),function(column,i){return _c('th',{key:'filter-' + column.Key,class:[
              { 'column-sort': _vm.providerCtx.sort == column.Key },
              { 'column-fixed': _vm.lockedColumns.includes(column.Key) },
              'filter'
            ],style:({
              width: column.Width + 'px',
              left: _vm.fixedFromLeft(column.Key, i)
            })},[_c('div',{staticClass:"filter-container"},[_vm._l((_vm.providerCtx.filters[column.Key]),function(value,filterI){return _c('span',{key:filterI,class:[
                  'filter-bubble',
                  value.length > 0 && value[0] === '!'
                    ? 'filter-is-not'
                    : 'filter-is'
                ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.providerCtx.filters[column.Key][filterI]),expression:"providerCtx.filters[column.Key][filterI]"},{name:"autowidth",rawName:"v-autowidth",value:({
                    maxWidth: 'none',
                    minWidth: '16px',
                    comfortZone: 2
                  }),expression:"{\n                    maxWidth: 'none',\n                    minWidth: '16px',\n                    comfortZone: 2\n                  }"}],ref:("filter" + (column.Key) + i),refInFor:true,attrs:{"type":"text"},domProps:{"value":(_vm.providerCtx.filters[column.Key][filterI])},on:{"click":function($event){$event.stopPropagation();return (function () {})($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.providerCtx.filters[column.Key], filterI, $event.target.value)}}}),_c('button',{staticClass:"remove-filter",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilter(column.Key, filterI)}}},[_c('svf-svg',{attrs:{"src":require('@/assets/icons/remove-filter.svg')}})],1)])}),_c('button',{staticClass:"add-filter",attrs:{"type":"button"},on:{"click":function($event){return _vm.addFilter(column.Key)}}},[_c('svf-svg',{attrs:{"src":require('@/assets/icons/add-filter.svg')}})],1)],2)])}),0)],1),_c('tbody',_vm._l((_vm.editRows),function(row,rowIndex){return _c('tr',{key:("tr-" + (row._id)),class:[
            _vm.rowStates[row._id] && _vm.rowStates[row._id].loading
              ? 'loading'
              : null
          ],attrs:{"data-row":rowIndex}},[_vm._l((_vm.editColumns),function(column,columnIndex){return _c('td',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
              function (isVisible) { return _vm.setVisibleItemsFunc(((row._id) + "-" + (column.Key)), isVisible); }
            ),expression:"\n              isVisible =>\n                setVisibleItemsFunc(`${row._id}-${column.Key}`, isVisible)\n            "}],key:column.Key,class:{
              'column-sort': _vm.providerCtx.sort == column.Key,
              'column-fixed': _vm.lockedColumns.includes(column.Key),
              'save-failed':
                _vm.rowStates[row._id].columns[column.Key] &&
                _vm.rowStates[row._id].columns[column.Key].failed
            },style:({
              width: column.Width + 'px',
              left: _vm.fixedFromLeft(column.Key, columnIndex)
            })},[(_vm.visibleItems[((row._id) + "-" + (column.Key))])?_c('div',{class:'column-type-' + column.Type,style:({ 'max-width': column.Width + 'px' })},[(_vm.checkable && columnIndex === 0)?_c('input',{attrs:{"type":"checkbox"},domProps:{"value":row._id,"checked":_vm.checkedRows.includes(row._id)},on:{"click":function($event){_vm.$emit('check-row', {
                    id: row._id,
                    value: !_vm.checkedRows.includes(row._id)
                  })}}}):_vm._e(),_c('span',{staticClass:"checkmark"}),_c(_vm.columnComponent(column.Type),{tag:"component",attrs:{"data":row,"column":column,"blur":false,"locked":false,"failed":false},on:{"update":function($event){return _vm.updateCell(rowIndex, $event.key, $event.value)}}}),_c('div',{class:[
                  'spinner-box',
                  _vm.rowStates[row._id].columns[column.Key] &&
                    _vm.rowStates[row._id].columns[column.Key].loading
                    ? 'spinner-showing'
                    : 'spinner-hidden'
                ]},[(
                    _vm.rowStates[row._id].columns[column.Key] &&
                      _vm.rowStates[row._id].columns[column.Key].loading
                  )?_c('LoadingSpinner'):_vm._e()],1)],1):_c('div',{staticClass:"column-ghost"},[_c('span')])])}),_c('td',{staticClass:"row-buttons"},[_c('button',{staticClass:"remove-row",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteRow(rowIndex)}}},[_c('font-awesome',{attrs:{"icon":['fal', 'trash-alt'],"fixed-width":""}})],1)])],2)}),0)])]),_c('button',{staticClass:"button add-product",attrs:{"type":"button"},on:{"click":_vm.createProduct}},[_c('font-awesome',{attrs:{"icon":['fal', 'plus-circle'],"fixed-width":""}}),_c('span',[_vm._v("Lägg till rad")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }