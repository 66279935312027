

























































































































































































































































































































import { PropType } from "vue";
import mixins from "vue-typed-mixins";
import _ from "lodash-es";
import draggable from "vuedraggable";

import ColumnTypesMixin from "@/components/ColumnTypesMixin";
import Pagination from "@/components/Pagination.vue";
import LoadingSpinner from "@/icons/LoadingSpinner.vue";
import EventBus from "@/event-bus";

interface ColState {
  loading: boolean;
  editing: boolean;
  locked: boolean;
  failed: boolean;
}
interface ColStates {
  [key: string]: ColState;
}
interface RowState {
  loading: boolean;
  columns: ColStates;
}
interface RowStates {
  [key: string]: RowState;
}

export default mixins(ColumnTypesMixin).extend({
  name: "DynamicTable",
  components: {
    draggable,
    Pagination,
    LoadingSpinner
  },
  props: {
    schema: {
      type: Object as PropType<any>,
      required: true
    },
    dataset: {
      type: Object as PropType<any>,
      required: true
    },
    columns: {
      type: Array as PropType<Array<any>>,
      required: true
    },
    ctx: {
      type: Object as PropType<any>,
      required: true
    },
    infiniteScroll: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    infiniteScrollDistance: {
      type: Number as PropType<number>,
      default: 1000
    },
    hideColumns: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    checkable: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    checkedRows: {
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    isSubtable: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data(): {
    loading: boolean;
    editRows: any[];
    visibleItems: { [key: string]: boolean };
    rowStates: RowStates;
    queryResult: {
      rows: any[];
      page: number | null;
      total: number | null;
      hasMoreRows: boolean;
    };
    providerCtx: any;
    refresh: Function;
    pushChanges: Function;
    handleTableScroll: Function;
    isDraggingColumns: boolean;
    resizingColumn: any | null;
    lockedColumns: any[];
    blurRows: boolean;
    tableScrollLeft: number;
  } {
    return {
      loading: false,
      editRows: [],
      rowStates: {},
      visibleItems: {},
      queryResult: {
        rows: [] as any[],
        page: null,
        total: null,
        hasMoreRows: false
      },
      providerCtx: _.cloneDeep(this.ctx),
      refresh: _.debounce(() => {
        (this as any).refreshNow();
      }, 100),
      pushChanges: _.debounce(() => {
        (this as any).pushChangesNow();
      }, 700),
      handleTableScroll: _.debounce(() => {
        (this as any).tableScrollLeft = (this.$refs
          .tablecontainer as HTMLElement).scrollLeft;
      }, 50),
      isDraggingColumns: false,
      resizingColumn: null,
      lockedColumns: [],
      blurRows: false,
      tableScrollLeft: 0
    };
  },
  computed: {
    editColumns: {
      set(newValue: any[]) {
        this.$emit(
          "updateColumns",
          newValue.map((col: any) => col.Key + "*" + (col.Width || 250))
        );
      },
      get(): any[] {
        const columnKeys = [] as string[];
        const columnWidth = new Map();
        this.columns.forEach((col: string) => {
          let parts = col.split("*");
          if (parts.length >= 2) {
            columnWidth.set(parts[0], parts[1]);
          } else {
            columnWidth.set(parts[0], 250);
          }
          columnKeys.push(parts[0]);
        });
        return this.schema.Columns.filter((col: any) =>
          columnKeys.includes(col.Key)
        )
          .sort((a: any, b: any) => {
            let aL = this.lockedColumns.indexOf(a.Key);
            let bL = this.lockedColumns.indexOf(b.Key);
            if (aL !== -1 && bL !== -1) {
              return aL - bL;
            } else if (aL !== -1) {
              return -1;
            } else if (bL !== -1) {
              return 1;
            }
            return columnKeys.indexOf(a.Key) - columnKeys.indexOf(b.Key);
          })
          .map((col: any) => {
            if (!col.Width) {
              col.Width = columnWidth.get(col.Key);
            }
            return col;
          });
      }
    },
    availableColumns: {
      get(): any {
        return this.schema.Columns.filter((col: any) =>
          this.editColumns.reduce((acc, c) => {
            if (c.Key === col.Key) {
              acc = false;
            }
            return acc;
          }, true)
        );
      },
      set() {}
    },
    entity() {
      return this.dataset ? this.dataset.Collection : null;
    },
    infiniteScrollDisabled(): boolean {
      return !this.queryResult.hasMoreRows || this.loading;
    }
  },
  watch: {
    fetchProvider() {
      this.providerCtx.filters = {};
      this.refresh();
    },
    providerCtx: {
      handler() {
        if (!_.isEqual(this.providerCtx, this.ctx)) {
          this.$emit("updateCtx", this.providerCtx);
        }
      },
      deep: true
    },
    ctx: {
      handler() {
        this.refresh();
      },
      deep: true
    },
    "queryResult.rows"() {
      this.editRows = _.cloneDeep(this.queryResult.rows);
      this.rowStates = {};
      this.editRows.forEach(row => {
        let cols = {} as ColStates;
        for (let col of this.schema.Columns) {
          cols[col.Key] = {
            loading: false,
            editing: false,
            locked: false,
            failed: false
          };
        }
        this.$set(this.rowStates, row._id, {
          loading: false,
          failed: false,
          columns: cols
        } as RowState);
      });
    },
    editRows: {
      handler() {
        this.pushChanges();
      },
      deep: true
    },
    view() {
      this.maybeResetColumns();
    },
    editColumns() {
      this.maybeResetColumns();
    },
    dataset() {
      this.rowStates = {};
      this.editRows = [];
      this.refresh();
    },
    schema() {
      this.resetColumns();
    },
    entity() {
      this.rowStates = {};
      this.editRows = [];
      this.refresh();
    }
  },
  mounted() {
    this.editRows = _.cloneDeep(this.queryResult.rows);
    this.maybeResetColumns();
    this.refreshNow();
    this.addHotkeys();
  },
  methods: {
    updateCell(rowIndex: number, key: string, value: any) {
      this.$set(this.editRows[rowIndex], key, value);
      this.pushChanges();
    },
    addFilter(columnKey: string) {
      var columnFilters = this.providerCtx.filters[columnKey] || [];

      if (
        columnFilters.length === 0 ||
        columnFilters[columnFilters.length - 1] !== ""
      ) {
        columnFilters.push("");
        this.$set(this.providerCtx.filters, columnKey, columnFilters);
      }

      const newIndex = columnFilters.length - 1;

      // on nextTick when the new filter is first rendered, focus the cursor on it
      this.$nextTick(() => {
        (this.$refs[`filter${columnKey}${newIndex}`] as any)[0].focus();
      });
    },
    removeFilter(columnKey: string, i: number) {
      this.providerCtx.filters[columnKey].splice(i, 1);
      if (this.providerCtx.filters[columnKey].length === 0) {
        delete this.providerCtx.filters[columnKey];
      }
    },
    refreshNow(nextPage: boolean = false) {
      if (this.loading) {
        console.log("already refreshing...");
        return;
      }
      this.loading = true;
      console.log("refreshing");

      let params = {} as any;
      if (Object.keys(this.providerCtx.filters).length > 0) {
        params["filters"] = JSON.stringify(
          this.cleanFilter(this.providerCtx.filters)
        );
      }
      if (nextPage && this.queryResult.page !== null) {
        params["page"] = this.queryResult.page + 1;
      } else {
        params["page"] = 1;
      }
      if (this.providerCtx.limit > 0) params["limit"] = this.providerCtx.limit;
      if (this.providerCtx.sort != null) params["sort"] = this.providerCtx.sort;
      if (this.providerCtx.order) params["order"] = this.providerCtx.order;

      this.$store.getters["data/list"](this.entity, params)
        .then((result: any) => {
          if (this.blurRows) {
            //this.blurRows = false;
            this.resetRows();
          }
          if (result.Data == null && !nextPage) {
            this.queryResult.total = 0;
            this.queryResult.rows = [];
            return;
          }
          if (nextPage) {
            if (result.Data == null) {
              this.queryResult.rows = [];
            } else {
              let distinct = this.queryResult.rows.map(row => row._id);
              let newRows = [];
              for (let newRow of result.Data.values()) {
                if (distinct.includes(newRow._id)) {
                  console.log("duplicate _id found");
                  continue;
                }
                distinct.push(newRow._id);
                newRows.push(_.cloneDeep(newRow));
              }
              this.queryResult.rows.push(...newRows);
            }
          } else {
            this.queryResult.rows = _.cloneDeep([...result.Data.values()]);
          }
          this.queryResult.page = result.Page;
          this.queryResult.total = result.Total;
          this.queryResult.hasMoreRows =
            result.Total > result.Offset + result.Limit;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    shallowDiff(a: any, b: any) {
      var changed = false;
      var diff = {} as any;
      a = a || {};
      b = b || {};
      for (let key in b) {
        if (!_.isEqual(a[key], b[key])) {
          changed = true;
          diff[key] = _.cloneDeep(b[key]);
        }
      }
      for (let key in a) {
        if (typeof b[key] === "undefined") {
          changed = true;
          diff[key] = null;
        }
      }
      return changed ? diff : null;
    },
    pushChangesNow() {
      let changes = this.editRows
        .map((editRow, editRowIndex) => ({
          _id: this.queryResult.rows[editRowIndex]._id,
          change: this.shallowDiff(this.queryResult.rows[editRowIndex], editRow)
        }))
        .filter(row => row.change !== null);

      if (changes.length === 0) {
        console.log("Nothing changed");
        return;
      }

      let pushSingleChange = (change: any) => {
        this.rowStates[change._id].loading = true;
        for (let col in change.change) {
          if (!this.rowStates[change._id].columns[col]) {
            this.rowStates[change._id].columns[col] = {
              failed: false,
              loading: true
            } as ColState;
          }
          this.rowStates[change._id].columns[col].loading = true;
        }
        this.$store
          .dispatch("data/patchSingle", {
            entity: this.entity,
            id: change._id,
            patch: change.change
          })
          .then(result => {
            let i = this.editRows.findIndex(row => row._id === result._id);
            if (i === -1) {
              return;
            }
            this.queryResult.rows[i] = result;
            for (let col in change.change) {
              this.rowStates[change._id].columns[col].failed = false;
            }
          })
          .catch(err => {
            console.log(err);
            for (let col in change.change) {
              this.rowStates[change._id].columns[col].failed = true;
            }
          })
          .finally(() => {
            this.rowStates[change._id].loading = false;
            for (let col in change.change) {
              this.rowStates[change._id].columns[col].loading = false;
            }
            console.log("DONE");
          });
      };

      changes.forEach(pushSingleChange);
    },
    maybeResetColumns() {
      if (this.editColumns.length === 0) {
        this.resetColumns();
      }
    },
    resetColumns() {
      this.editColumns = this.schema.Columns.filter(
        (col: any) => col.Default
      ).sort((a: any, b: any) => a.Default - b.Default);
    },
    createProduct() {
      this.$store
        .dispatch("data/get", {
          entity: this.entity,
          path: "create"
        })
        .then((result: any) => {
          let doc = document.documentElement;
          let top =
            (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
          if (top > 0) window.scrollTo({ top: 0, behavior: "smooth" });
          this.queryResult.rows.unshift(result);
          this.queryResult.total =
            this.queryResult.total !== null ? this.queryResult.total + 1 : 1;
        });
    },
    deleteRow(index: number) {
      (this as any).$confirm({
        message: "Vill du verkligen radera produkten?",
        button: {
          no: "Nej",
          yes: "Ja"
        },
        callback: (confirm: boolean) => {
          if (!confirm) return;
          this.$store
            .dispatch("data/delete", {
              entity: this.entity,
              id: this.queryResult.rows[index]._id
            })
            .then(() => {
              this.queryResult.rows.splice(index, 1);
              if (this.queryResult.total !== null) {
                this.queryResult.total--;
              }
            });
        }
      });
    },
    addHotkeys() {
      window.addEventListener("keydown", (e: KeyboardEvent) => {
        if (
          !(e.target instanceof Element) ||
          e.target.nodeName == "INPUT" ||
          e.target.nodeName == "TEXTAREA"
        )
          return;
        if (e.key == "n") this.createProduct();
        if (e.key == "d") {
          const row = document.querySelectorAll(":hover")[
            document.querySelectorAll(":hover").length - 1
          ];
          if (row.closest("[data-row]")) {
            this.deleteRow((row.closest("[data-row]") as any).dataset.row);
          }
        }
      });
    },
    setSort(sort: string) {
      if (this.resizingColumn !== null) {
        return;
      }
      if (this.providerCtx.sort != sort) {
        this.providerCtx.sort = sort;
        //this.blurRows = true;
      }
      this.setOrder(null);
    },
    setOrder(order: string | null = null) {
      if (order == null)
        order = this.providerCtx.order == "desc" ? "asc" : "desc";
      if (this.providerCtx.order == order) return;
      this.providerCtx.order = order;
      //this.blurRows = true;
    },
    cleanFilter(filters: any) {
      let f = {} as any;
      for (const [key, val] of Object.entries(filters) as [string, any]) {
        if (val.filter((v: any) => v != "").length > 0)
          f[key] = val.filter((v: any) => v != "");
      }
      return f;
    },
    setLimit(limit: number) {
      if (this.providerCtx.limit == limit) return;
      this.providerCtx.limit = limit;
      //this.blurRows = true;
    },
    loadMore() {
      if (!this.queryResult.hasMoreRows) {
        console.warn(
          "loadMore was requested but there are no more rows to be fetched"
        );
        return;
      }
      this.refreshNow(true);
    },
    resetRows() {
      if (!this.infiniteScroll) return;
      this.queryResult.rows = [];
      this.refresh();
    },
    doExport(): void {
      let params = {} as { [key: string]: string };
      if (Object.keys(this.providerCtx.filters).length > 0) {
        params["filters"] = JSON.stringify(
          this.cleanFilter(this.providerCtx.filters)
        );
      }
      params["columns"] = this.editColumns.map((c: any) => c.Key).join(",");
      this.$store.getters["data/export"](this.entity, params).then(
        (result: any) => {
          console.log(result);
        }
      );
      console.log("DOING THE EXPORT");
    },
    columnResize(event: MouseEvent) {
      if (this.resizingColumn === null) {
        return;
      }
      this.resizingColumn.Width =
        parseInt(this.resizingColumn.Width) + event.movementX;
    },
    columnResizeStart(column: any) {
      this.resizingColumn = column;
    },
    columnResizeStop() {
      if (this.resizingColumn === null) {
        return;
      }
      setTimeout(() => {
        this.resizingColumn = null;
        this.editColumns = _.cloneDeep([...this.editColumns]);
      }, 100);
    },
    columnToggleLock(column: any) {
      let index = this.lockedColumns.indexOf(column.Key);
      if (index === -1) {
        this.lockedColumns.push(column.Key);
      } else {
        this.lockedColumns.splice(index, 1);
      }
    },
    fixedFromLeft(key: string, ti: number) {
      let totalLeft = 0;
      for (let i in this.editColumns) {
        if (parseInt(i) >= ti || parseInt(i) >= this.lockedColumns.length)
          break;
        totalLeft += parseInt(this.editColumns[i].Width);
      }
      if (!this.lockedColumns.includes(key)) {
        return totalLeft + "px";
      }
      return this.tableScrollLeft + totalLeft + "px";
    },
    setVisibleItemsFunc(key: string, isVisible: boolean) {
      let that = this as any;
      if (that.visibleItems[key] === isVisible) {
        return;
      }
      that.visibleItemsQueue[key] = isVisible;
      if (!that.visibleItemsQueueTodo) {
        setTimeout(this.flushVisibleItemsQueue, 10);
      }
      that.visibleItemsQueueTodo = true;
    },
    async flushVisibleItemsQueue() {
      let that = this as any;
      if (!that.visibleItemsQueueTodo) {
        return;
      }
      console.log("flushing queue");
      let newVisibleItems = that.visibleItemsQueue;
      for (let k in this.visibleItems) {
        if (!Object.prototype.hasOwnProperty.call(newVisibleItems, k)) {
          newVisibleItems[k] = this.visibleItems[k];
        }
      }
      this.visibleItems = newVisibleItems;
      that.visibleItemsQueue = {};
      that.visibleItemsQueueTodo = false;
    }
  },
  created() {
    let that = this as any;
    that.visibleItemsQueue = {};
    that.visibleItemsQueueTodo = false;
    //that.visibleItemsWorker = setInterval(this.flushVisibleItemsQueue, 50)
    EventBus.$on("do-export", this.doExport);
  },
  beforeDestroy() {
    let that = this as any;
    that.visibleItemsQueue = {};
    that.visibleItemsQueueTodo = false;
    EventBus.$off("do-export");
  }
});
