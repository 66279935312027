























































































































































































import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    dataset: {
      type: Object as PropType<any>,
      default: '',
      required: true
    },
    preview: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data() {
    return {
      file: null as any,
      sending: false as boolean,
      error: '' as string,
      importType: '' as string
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.file = null;
      this.sending = false;
    },
    upload() {
      this.sending = true;
      let formData = new FormData();
      formData.append('dataset', this.dataset._id);
      formData.append('type', this.importType);
      formData.append('file', this.file, this.file.name);
      this.$store.dispatch("data/post", {
        entity: 'import/upload',
        formData: formData
      }).then((result) => {
        console.log(result)
        this.reset();
        this.$store.dispatch('data/updateDatasets').then(() => {
          this.$router.push({ params: { dataset: result.dataset } });
          this.$emit('closed', true);
        })
        this.error = ''
      }).catch((err: any) => {
        this.reset()
        console.log(err.response)
        if (err.response && err.response.data) {
          this.error = err.response.data
        } else {
          this.error = err.toString()
        }
      });
    },
    mergePreview() {
      this.$store.dispatch("data/get", {
        entity: 'import/merge',
        path: this.dataset._id
      }).then((result) => {
        this.reset();
        this.$store.dispatch('data/updateDatasets').then(() => {
          this.$router.push({ params: { dataset: result.dataset } });
          this.$emit('closed', true);
        });
        this.error = ''
      }).catch((err: any) => {
        this.reset()
        if (err.response && err.response.data) {
          this.error = err.response.data
        } else {
          this.error = err.toString()
        }
      })
    },
    deletePreview() {
      this.$store.dispatch("data/delete", {
        entity: 'import/delete',
        id: this.dataset._id,
      }).then((result) => {
        this.reset();
        this.$store.dispatch('data/updateDatasets').then(() => {
          this.$router.push({ params: { dataset: result.dataset } });
          this.$emit('closed', true);
        });
        this.error = ''
      }).catch((err: any) => {
        this.reset()
        if (err.response && err.response.data) {
          this.error = err.response.data
        } else {
          this.error = err.toString()
        }
      })
    },
    onChange() {
      this.file = (this.$refs.file as any).files[0];
    },
    dragOver(event: DragEvent) {
      event.preventDefault();
      let filebox = this.$refs.filebox as any
      if (!filebox.classList.contains('has-background-primary-light')) {
        filebox.classList.remove('has-background-success-light');
        filebox.classList.add('has-background-success-light');
      }
    },
    dragLeave() {
      let filebox = this.$refs.filebox as any
      filebox.classList.add('has-background-success-light');
      filebox.classList.remove('has-background-primary-light');
    },
    drop(event: DragEvent) {
      event.preventDefault();
      let filebox = this.$refs.filebox as any
      let file = this.$refs.file as any

      if (event.dataTransfer === null) {
        console.log('dataTransfer is null')
        return
      }

      if (event.dataTransfer.files[0].type != 'text/csv') {
        filebox.classList.remove('has-background-primary-light');
        filebox.classList.remove('has-background-success-light');
        filebox.classList.add('has-background-danger-light');
        alert('Endast .csv filer.');
        filebox.classList.remove('has-background-danger-light');
        this.reset();
        return;
      }

      file.files = event.dataTransfer.files;
      this.onChange();
      filebox.classList.add('has-background-success-light');
      filebox.classList.remove('has-background-primary-light');
    },
    setImportType(type: string) {
      this.importType = type
    }
  }
})
