









import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'LoadingSpinner',
  props: {
    running: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  }
})
