













import mixins from 'vue-typed-mixins'
import ColMixin from './ColMixin.vue'

export default mixins(ColMixin).extend({
  name: "BooleanCol",
  methods: {}
});
