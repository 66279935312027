







































































































































import Vue, { PropType } from 'vue'
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import MediaPreview from '@/components/MediaPreview.vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

interface Folder {
  Name: string,
  Key: String
}

export default Vue.extend({
  name: 'MediaExplorer',
  components: {
    Dashboard,
    MediaPreview
  },
  props: {
    prefix: {
      type: String as PropType<string>,
      required: true
    },
    selector: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    selectorItems: {
      type: Array as PropType<Array<string>>,
      default: () => [] as Array<string>
    }
  },
  data() {
    return {
      newFolderName: '' as string,
      loading: false,
      showUploadForm: false,
      folderContents: [] as any[]
    }
  },
  computed: {
    folderTree () : Folder[] {
      let parts = String(this.prefix || '').split('/').filter(part => part !== '')
      let tree = [] as Folder[]
      tree.push({
        Name: "root",
        Key: ""
      })
      for (let part of parts) {
        tree.push({
          Name: part,
          Key: tree[tree.length-1].Key + part + '/'
        })
      }
      return tree
    },
    uppy () {
     let that = this;
      return Uppy<Uppy.StrictTypes>()
        .use(AwsS3, {
          limit: 4,
          getUploadParameters: (file): Promise<any> => {
            return fetch('/api/media/upload', {
              method: 'post',
              headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                Filename: that.folderTree[that.folderTree.length-1].Key + file.name,
                ContentType: file.type
              })
            }).then((response) => {
              return response.json()
            }).then((data) => {
              return data as AwsS3.AwsS3UploadParameters
            })
          }
        })
        .on('complete', () => {
          that.refreshFolderContent()
        })
    }
  },
  watch: {
    prefix: {
      handler () {
        this.refreshFolderContent()
      },
      immediate: true
    }
  },
  beforeDestroy () {
    this.uppy.close()
  },
  methods: {
    createFolder () {
      let key = (this.$route.query.prefix || '') + this.newFolderName + '/'
      this.newFolderName = ''
      this.$emit('navigate', key)
    },
    deleteByKey (key: string) {
      return fetch('/api/media', {
        method: 'delete',
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          Key: key,
        })
      }).then(() => {
        this.refreshFolderContent()
      })
    },
    refreshFolderContent () {
      this.loading = true
      return fetch('/api/media/explore?prefix=' + (this.prefix || ''), {
        method: 'get',
        headers: {
          'accept': 'application/json',
        }
      }).then((response) => {
        return response.json()
      }).then((data) => {
        this.folderContents = data
      }).finally(() => {
        this.loading = false
      })
    }
  }
});
