



















import Vue, { PropType } from "vue";
import _ from "lodash-es";

// Hack to get types to work for async computed.
// @see https://github.com/foxbenjaminfox/vue-async-computed/issues/100
type AsyncComputedFields = {
  schema?: any;
  dataset?: any;
}

export default Vue.extend({
  name: "TableInTable",
  props: {
    multiple: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    selected: {
      type: [Array, String] as PropType<Array<string> | string>,
      default: null
    },
    source: {
      type: String as PropType<string>,
      required: true
    }
  },
  data() {
    const rtn = {
      loading: true,
      columns: "",
      providerCtx: {
        limit: 50,
        filters: {},
        sort: null,
        order: "asc"
      }
    }
    return rtn as typeof rtn & AsyncComputedFields;
  },
  computed: {
    currentCheckedRows() {
      if (this.multiple && !Array.isArray(this.selected)) {
        return [this.selected];
      }
      return this.multiple ? this.selected : [this.selected];
    }
  },
  asyncComputed: {
    schema () : Promise<any> {
      if (!this.dataset) {
        return Promise.resolve(null);
      }
      return this.$store.dispatch('data/single', { entity: 'Schema', id: this.dataset.Schema })
    },
    dataset() : Promise<any> {
      return this.$store.dispatch('data/single', { entity: 'Dataset', id: this.source })
    }
  },
  methods: {
    resetProviderCtx() {
      this.providerCtx.limit = 50;
      this.providerCtx.filters = {};
      this.providerCtx.sort = null;
      this.providerCtx.order = "asc";
    },
    setSelected(id: string, val: boolean) {
      let newSelected = _.cloneDeep(this.selected) as string | string[] | null;
      if (this.multiple) {
        if (newSelected === null) {
          newSelected = []
        }
        if (!Array.isArray(newSelected)) {
          newSelected = [newSelected];
        }
        if (val && !newSelected.includes(id)) {
          newSelected.push(id);
        } else if (!val) {
          newSelected = newSelected.filter(s => s !== id);
        }
      } else {
        if (val) {
          newSelected = id;
        } else {
          newSelected = null;
        }
      }
      this.$emit("update", newSelected);
    },
    updateColumns(col: string[]) {
      let newCol = col.join(",");
      if (newCol != this.columns) {
        this.columns = newCol;
      }
    },
    updateCtx(newCtx: any) {
      this.providerCtx.limit = _.cloneDeep(newCtx.limit);
      this.providerCtx.filters = _.cloneDeep(newCtx.filters);
      this.providerCtx.sort = _.cloneDeep(newCtx.sort);
      this.providerCtx.order = _.cloneDeep(newCtx.order);
    }
  }
});
