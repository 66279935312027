




















import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: "ValueLengthCol",
  props: {
    type: {
      type: String as PropType<string>,
      required: true
    },
    value: {
      type: Number as PropType<number>,
      default: 0
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.value != null ? this.value : 0
    };
  },
  computed: {
    label() {
      const values = {
        number: 'siffror',
        string: 'tecken',
        text: 'tecken',
        multiselect: 'val',
        row: 'rad'
      } as { [index: string]: string };
      return values[this.type];
    }
  },
  methods: {
    change() {
      this.$emit('change', this.inputValue);
    },
    keypress(evt: KeyboardEvent) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    }
  }
});
