












































































import Vue from 'vue'
import Modal from '@/components/Modal.vue'
import Importer from '@/components/Importer.vue'
import EventBus from '@/event-bus'

export default Vue.extend({
  name: 'Header',
  components: {
    Modal,
    Importer,
  },
  data() {
    return {
      showUpload: false,
    };
  },
  computed: {
    datasetID() {
      return this.$route.params.dataset ? this.$route.params.dataset : null;
    },
    datasets () {
      return this.$store.getters["data/getDatasets"]
    },
    schemaDataset (): any {
      if (!this.datasets) {
        return null
      }
      return this.datasets.find((set: any) => set.Collection === 'Schema')
    },
    dataset(): any {
      if (this.datasetID === null) {
        return null;
      }
      const ds = this.datasets.find((d: any) => d._id == this.datasetID);
      return ds ? ds : null;
    },
    isPreview(): any {
      return this.dataset != null ? this.dataset.Preview === true : false;
    },
  },
  methods: {
    toggleShowColumns() {
      this.$store.commit('ui/toggleShowColumns')
    },
    doExport () {
      EventBus.$emit('do-export')
    }
  },
});
