






















import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    show: {
      type: Boolean as PropType<Boolean>,
      default: false
    }
  },
  data() {
    return {
      showModal: this.show
    }
  },
  watch: {
    show(value) {
      this.showModal = value;
    }
  },
  methods: {
    close() {
      this.showModal = false;
      this.$emit('closed', this.showModal);
    }
  }
})
