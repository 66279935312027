import Vue from 'vue'

import ArrayCol from '@/components/columns/ArrayCol.vue'
import BooleanCol from '@/components/columns/BooleanCol.vue'
import DateCol from '@/components/columns/DateCol.vue'
import LinkCol from '@/components/columns/LinkCol.vue'
import ManyToOneCol from '@/components/columns/ManyToOneCol.vue'
import MediaCol from '@/components/columns/MediaCol.vue'
import MoneyCol from '@/components/columns/MoneyCol.vue'
import MultiSelectCol from '@/components/columns/MultiSelectCol.vue'
import NumberCol from '@/components/columns/NumberCol.vue'
import OneToManyCol from '@/components/columns/OneToManyCol.vue'
import StringCol from '@/components/columns/StringCol.vue'
import TextCol from '@/components/columns/TextCol.vue'


export default Vue.extend({
  components: {
    ArrayCol,
    BooleanCol,
    DateCol,
    LinkCol,
    ManyToOneCol,
    MediaCol,
    MoneyCol,
    MultiSelectCol,
    NumberCol,
    OneToManyCol,
    StringCol,
    TextCol
  },
  methods: {
    columnComponent(columnType: string) {
      switch (columnType) {
        case 'array':
          return 'ArrayCol'
        case 'boolean':
          return 'BooleanCol'
        case 'date':
          return 'DateCol'
        case 'link':
          return 'LinkCol'
        case 'many-to-one':
          return 'ManyToOneCol'
        case 'media':
          return 'MediaCol'
        case 'money':
          return 'MoneyCol'
        case 'multiselect':
          return 'MultiSelectCol'
        case 'number':
          return 'NumberCol'
        case 'one-to-many':
          return 'OneToManyCol'
        case 'text':
          return 'TextCol';
        case 'string':
        default:
          return 'StringCol'
      }
    }
  }
})