import { Module } from 'vuex'

const State = {
  showColumns: false
}

export default {
  namespaced: true,
  state: () => State,
  getters: {
    showColumns(state) {
      return state.showColumns;
    }
  },
  mutations: {
    toggleShowColumns(state) {
      state.showColumns = !state.showColumns
    },
    hideColumns(state) {
      state.showColumns = false
    }
  }
} as Module<typeof State, {}>;
