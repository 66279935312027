import { DirectiveOptions } from 'vue'

const directive: DirectiveOptions = {
  inserted (el, binding) {
    if (el.nodeName !== 'TABLE') { throw new Error('This directive is only valid on a table!'); }

    const opt = binding.value || {};
    const table = el;
    const thead = table.querySelector('thead');
    if (thead === null) { throw new Error('thead not found'); }

    const ths = thead.querySelectorAll('tr:first-child > th') as NodeListOf<HTMLElement>;
    const calcTableWidth = () => {
        let tableWidth = 0;
        let width = 0;

        ths.forEach((th) => {
            if (th.style.width) {
                width = Number(parseInt(th.style.width));
            } else {
                width = th.offsetWidth;
            }
            tableWidth += width;
        }, 0);

        return tableWidth.toString();
    };
    const applyTableWidth = () => {
        if (opt.fixedWidthTable) {
            table.style.width = calcTableWidth();
            table.style.maxWidth = 'none';
        } else if (!table.style.width) {
            //table.style.width = '100%';
            //table.style.maxWidth = '100%';
        }
    };
    const handleResizeForElements = (a: HTMLElement, b: HTMLElement, movement: number) => {
        if (!opt.fixedWidthTable) {
            console.log(parseInt(a.style.width) + movement + 'px')
            a.style.width = parseInt(a.style.width) + movement + 'px';
            b.style.width = parseInt(b.style.width) - movement + 'px';
        } else {
            a.style.width = parseInt(a.style.width) + movement + 'px';
            table.style.width = parseInt(table.style.width) + movement + 'px';
        }

    }
    const handleResize = (e: MouseEvent) => {
        if (opt.resizable === false || activeTh == null) return;

        const activeThIndex = parseInt(activeTh.getAttribute('data-col-index') || '-1')
        const neighbourghThIndex = parseInt(neighbourghTh.getAttribute('data-col-index') || '-1')

        const activeThs = thead.querySelectorAll('th[data-col-index="'+activeThIndex+'"]') as NodeListOf<HTMLElement>
        const neighbourghThs = thead.querySelectorAll('th[data-col-index="'+neighbourghThIndex+'"]') as NodeListOf<HTMLElement>

        for (let i=0; i<activeThs.length; i++) {
          handleResizeForElements(activeThs[i], neighbourghThs[i], e.movementX)
        }
        if (table.style.tableLayout !== 'fixed') {
          //table.style.tableLayout = 'fixed';
        }
    };

    let activeTh = null as HTMLElement | null; // the th being resized
    let neighbourghTh = null as any; // the next except when the last column is being resized in that case it is the previous
    let resizing = false; // a resize started needed because we can not detect event handler was attached or not
    table.style.position = 'relative';
    table.style.display = 'table';

    applyTableWidth();
    const trs = thead.querySelectorAll('tr') as NodeListOf<HTMLElement>

    trs.forEach(tr => {
      const trths = tr.querySelectorAll('th') as NodeListOf<HTMLElement>
      trths.forEach((trth, trthIndex) => {
        if (!trth.style.width) {
            trth.style.width = trth.clientWidth + 'px';
        }
        trth.setAttribute('data-col-index', trthIndex.toString());
        trth.setAttribute('data-original-width', trth.style.width);
      })
    })

    ths.forEach(th => {
        // initilise the width if th does not already have it

        const bar = document.createElement('div');

        bar.style.position = 'absolute';
        bar.style.right = '0';
        bar.style.top = '0';
        bar.style.bottom = '0';
        bar.style.height = '9em';
        bar.style.cursor = 'col-resize';
        bar.style.border = '2px solid red';

        // customisable options
        bar.style.width = opt.handleWidth || '8px';
        bar.style.zIndex = opt.zIndex || 1;
        bar.className = opt.handleClass || 'columns-resize-bar';

        bar.addEventListener('mousedown', (e) => {
            if (e.target === null || !(e.target instanceof HTMLElement)) {
              return;
            }
            // element with a fixedsize attribute will be ignored
            if (e.target.parentElement === null || e.target.parentElement.getAttribute('fixedsize')) {
                return;
            }
            resizing = true;
            document.body.addEventListener('mousemove', handleResize);
            document.body.style.cursor = 'col-resize';
            document.body.style.userSelect = 'none';

            activeTh = e.target.parentElement;
            neighbourghTh = activeTh.nextElementSibling;
            if (!neighbourghTh) {
                neighbourghTh = activeTh.previousElementSibling;
            }

            // calculate table size if the table width is fixed
            if (!opt.fixedWidthTable) {
                const tableWidth = [...ths].reduce((a, th) => {
                    return a + th.clientWidth;
                }, 0);
                table.style.width = tableWidth + 'px';
            }
        });

        th.appendChild(bar);
    });

    document.addEventListener('mouseup', () => {
        if (!resizing) return;
        resizing = false;
        document.body.removeEventListener('mousemove', handleResize);
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
        if (typeof opt.afterResize === 'function') {
            opt.afterResize(ths);
        }
    });

    // resets the column sizes
    (el as any).$resetColumnSizes = () => {
      ths.forEach((th) => {
          th.style.width = th.getAttribute('data-original-width') || '';
      }, 0);
      applyTableWidth();
    };
    (el as any).$applyColumnSizes = () => {
      trs.forEach(tr => {
        const trths = tr.querySelectorAll('th') as NodeListOf<HTMLElement>
        trths.forEach(trth => {
          trth.style.width = trth.clientWidth + 'px';
          trth.setAttribute('data-original-width', trth.style.width);
        }, 0);
      }, 0);
      applyTableWidth();
    };
  }
}
export default directive