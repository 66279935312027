




import Vue, { PropType } from 'vue'

// Hack to get types to work for async computed.
// @see https://github.com/foxbenjaminfox/vue-async-computed/issues/100
type AsyncComputedFields = {
  dataset?: any;
  entity?: any;
  schema?: any;
}

export default Vue.extend({
  name: 'EntityReference',
  props: {
    entityId: {
      type: String as PropType<string>,
      required: true
    },
    source: {
      type: String as PropType<string>,
      required: true
    }
  },
  data () {
    const data = {}
    return data as typeof data & AsyncComputedFields;
  },
  asyncComputed: {
    dataset(): any {
      return this.$store.dispatch('data/single', { entity: 'Dataset', id: this.source })
    },
    entity (): any {
      if (!this.dataset) {
        return null
      }
      return this.$store.dispatch('data/single', { entity: this.dataset.Collection, id: this.entityId })
    },
    schema (): any {
      if (!this.dataset) {
        return null
      }
      return this.$store.dispatch('data/single', { entity: 'Schema', id: this.dataset.Schema })
    }
  },
  computed: {
    entityTitle (): any {
      if (this.$asyncComputed.entity.updating) {
        return '...'
      }
      if (this.$asyncComputed.entity.error) {
        return 'ERROR'
      }
      if (!this.entity || !this.schema) {
        return '...'
      }
      return this.entity[this.schema.DefaultColumns[0]]
    },
  }
})
