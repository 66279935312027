





































import mixins from 'vue-typed-mixins'
import ColMixin from './ColMixin.vue'
import MediaPreview from '@/components/MediaPreview.vue'
import MediaExplorer from '@/components/MediaExplorer.vue'

export default mixins(ColMixin).extend({
  name: "MediaCol",
  data() {
    return {
      editing: false,
      showMediaExplorer: false,
      explorerPrefix: ''
    };
  },
  computed: {
    medias () : string[] {
      let value = this.data[this.column.Key]
      if (Array.isArray(value)) {
        return value.filter((v, i, self) => self.indexOf(v) === i)
      }
      if (['', undefined, null].includes(value)) {
        return []
      }
      return [value]
    }
  },
  methods: {
    removeMedia(key: string) : void {
      this.updateMedia(this.medias.filter(media => media !== key))
    },
    updateMedia(keys: string[]) : void {
      this.update(this.column.Key, keys)
    },
    hideMediaExplorer() : void {
      this.showMediaExplorer = false
    }
  },
  components: { MediaPreview, MediaExplorer }
});
