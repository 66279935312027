
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    },
    column: {
      type: Object as PropType<any>,
      required: true
    },
    blur: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  methods: {
    update (key: string, newValue: any): void {
      this.$emit('update', {key: key, value: newValue})
    }
  }
})
