














import Vue from 'vue'
import Header from '@/components/Header.vue'

export default Vue.extend({
  components: { Header },
  data () {
    return {
      VUE_APP_ENV: process.env.VUE_APP_ENV
    }
  }
})
