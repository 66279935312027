



























import mixins from 'vue-typed-mixins'
import ColMixin from "./ColMixin.vue";

export default mixins(ColMixin).extend({
  name: "StringCol",
  mixins: [ColMixin],
  data() {
    return {
      editing: false,
      style: {} as any,
      styleOuter: {} as any
    };
  },
  /*
  watch: {
    blur(bool: boolean) {
      if (bool) this.style.maxWidth = this.$refs.input.offsetWidth + 'px';
      this.styleOuter.width = bool ? this.$refs.outer.offsetWidth + 'px' : null;
    }
  }
  */
});
