






























import mixins from 'vue-typed-mixins'
import ColMixin from "./ColMixin.vue";

export default mixins(ColMixin).extend({
  name: "TextCol",
  data() {
    return {
      editing: false,
      style: {} as any,
      styleOuter: {} as any
    };
  },
  computed: {
    value() {
      return this.data[this.column.Key];
    }
  },
  watch: {
    blur(bool) {
      if (bool) this.style.maxWidth = (this.$refs.textarea as any).offsetWidth + 'px';
      this.styleOuter.width = bool ? (this.$refs.outer as any).offsetWidth + 'px' : null;
    }
  },
  methods: {
    keydown(event: KeyboardEvent) {
      if (this.column.MaxRowValue !== null
        && event.target instanceof HTMLInputElement
        && event.target.value.split('\n').length >= this.column.MaxRowValue) {
        event.preventDefault();
      }
    }
  }
});
