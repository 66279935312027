

































import OneToManyCol from "./OneToManyCol.vue";

export default OneToManyCol.extend({
  name: "ManyToOneCol"
})
