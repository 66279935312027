




























import mixins from 'vue-typed-mixins'
import ColMixin from "./ColMixin.vue";

export default mixins(ColMixin).extend({
  name: "NumberCol",
  data() {
    return {
      editing: false,
      style: {} as any,
      styleOuter: {} as any
    };
  },
  watch: {
    blur(bool) {
      if (bool) this.style.maxWidth = (this.$refs.input as any).offsetWidth + 'px';
      this.styleOuter.width = bool ? (this.$refs.outer as any).offsetWidth + 'px' : null;
    }
  }
});
